import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const SpalshScreen = () => {
    const navigate = useNavigate()
    useEffect(()=>{
        const timer = setTimeout(()=>{
            navigate('/home')
        },5000)
        return () => clearTimeout(timer);
    },[])
  return (
    <div>SpalshScreen</div>
  )
}

export default SpalshScreen